import {
  all,
  call,
  put,
  takeEvery,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  setFavouritePoemSearchResults,
  setNotFavouritePoemSearchResults,
  searchPoemsSuccess,
  searchPoemsFail,
  searchPoemsStart,
} from "./search.actions";
import SearchActionTypes from "./search.types";
import FavouritesActionTypes from "../favourites/favourites.types";
import PoemsActionTypes from "../poems/poems.types";

export const selectAllPoems = (state) => state.poems.allPoems;

export const selectAllFavourites = (state) => state.favourites.allFavourites;

export const selectNotFavouritePoems = (state) =>
  state.poems.allPoems.filter(
    (p1) => !state.favourites.allFavourites.some((p2) => p1.id === p2.id)
  );

function* searchPoems(action) {
  try {
    yield put(searchPoemsStart());
    const favourites = yield select(selectAllFavourites);
    const notFavourites = yield select(selectNotFavouritePoems);
    yield put(setFavouritePoemSearchResults(favourites));
    yield put(setNotFavouritePoemSearchResults(notFavourites));
    yield put(searchPoemsSuccess());
  } catch (e) {
    yield put(searchPoemsFail(e.message));
  }
}

export function* onSearchParamChange() {
  yield takeEvery(
    [
      SearchActionTypes.SET_SEARCH_IN_VERSE,

      FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_SUCCESS,
      FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_SUCCESS,

      PoemsActionTypes.LOAD_POEMS_SUCCESS,
    ],
    searchPoems
  );
}

export function* onSearchPhraseChange() {
  yield takeLatest([SearchActionTypes.SET_SEARCH_PHRASE], searchPoems);
}

export function* searchSagas() {
  yield all([call(onSearchParamChange), call(onSearchPhraseChange)]);
}
