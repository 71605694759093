import { Container } from "react-bootstrap";
import styled, { css } from "styled-components";

export const SpinnerContainer = styled(Container)`
  min-height: 150px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: 2s fadeIn linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  ${(props) =>
    props.fullscreen === "true" &&
    css`
      margin-top: 10vh;
      min-height: 85vh;
    `}
`;

export const StyledSpinnerContainer = styled.div`
  .spinner-border {
    min-width: 100px;
    min-height: 100px;

    animation: rainbox 5s infinite, spinner-border linear 2s infinite;
    @keyframes rainbox {
      0% {
        color: #f98b52;
      }
      10% {
        color: #9b4a1f;
      }
      20% {
        color: #3a1806;
      }
      30% {
        color: #78ba1c;
      }
      40% {
        color: #b1fcb3;
      }
      50% {
        color: #038082;
      }
      60% {
        color: #65dadc;
      }
      70% {
        color: #074b8e;
      }
      80% {
        color: #70adea;
      }
      90% {
        color: #4d1291;
      }
      100% {
        color: #927cab;
      }
    }
  }
`;

export const SpinnerText = styled.div`
  font-size: 1.2em;
  animation: rainbox 5s infinite, pulse 2s infinite;
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes rainbox {
    0% {
      color: #f98b52;
    }
    10% {
      color: #9b4a1f;
    }
    20% {
      color: #3a1806;
    }
    30% {
      color: #78ba1c;
    }
    40% {
      color: #b1fcb3;
    }
    50% {
      color: #038082;
    }
    60% {
      color: #65dadc;
    }
    70% {
      color: #074b8e;
    }
    80% {
      color: #70adea;
    }
    90% {
      color: #4d1291;
    }
    100% {
      color: #927cab;
    }
  }
`;
