import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import {
  StyledLink,
  ThemeSelectorContainer,
  ThemeSymbol,
  ThemeToggleButton,
  StyledImage,
} from "./Header.styles";

import { switchThemesStart } from "../../redux/theme/theme.actions";
import { selectCurrentTheme } from "../../redux/theme/theme.selectors";

const Header = ({ currentTheme, switchThemes }) => {
  const handleThemeChange = () => {
    switchThemes(currentTheme.name);
  };
  return (
    <Navbar bg="navBG" expand="sm" className="px-1 text-center" fixed="top">
      <Container>
        <StyledLink to="/">
          <StyledImage
            src="https://firebasestorage.googleapis.com/v0/b/versesmesek-706db.appspot.com/o/static%2Fimages%2Flogo64x64.webp?alt=media&token=54c4d8ea-96a0-4dfb-b4f4-9f343b99b825"
            srcSet="https://firebasestorage.googleapis.com/v0/b/versesmesek-706db.appspot.com/o/static%2Fimages%2Flogo64x64.png?alt=media&token=60c26b39-f570-47f2-bf63-2602a987db7c"
            alt="VM logo"
            className="d-inline-block align-top"
          />{" "}
          Verses Mesék
        </StyledLink>
        <Navbar.Toggle />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0">
            <NavLink to="/mesek">Mesék</NavLink>
          </Nav>
          <ThemeSelectorContainer onClick={handleThemeChange}>
            {currentTheme.name === "lightTheme" ? (
              <React.Fragment>
                <ThemeSymbol>🌞</ThemeSymbol>
                <ThemeToggleButton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ThemeToggleButton />
                <ThemeSymbol>🌛</ThemeSymbol>
              </React.Fragment>
            )}
          </ThemeSelectorContainer>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  currentTheme: selectCurrentTheme(state),
});

const mapDispatchToProps = (dispatch) => ({
  switchThemes: () => dispatch(switchThemesStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
