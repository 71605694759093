import SearchActionTypes from "./search.types";

export const setSearchText = (text) => ({
  type: SearchActionTypes.SET_SEARCH_PHRASE,
  payload: text,
});

export const setSearchInVerse = (option) => ({
  type: SearchActionTypes.SET_SEARCH_IN_VERSE,
  payload: option,
});

export const searchPoemsStart = () => ({
  type: SearchActionTypes.SEARCH_POEMS_START,
});

export const searchPoemsSuccess = () => ({
  type: SearchActionTypes.SEARCH_POEMS_SUCCESS,
});

export const searchPoemsFail = (errorMessage) => ({
  type: SearchActionTypes.SEARCH_POEMS_FAIL,
  payload: errorMessage,
});

export const setFavouritePoemSearchResults = (filteredFavPoems) => ({
  type: SearchActionTypes.SET_FAVOURITE_POEMS_SEARCH_RESULTS,
  payload: filteredFavPoems,
});

export const setNotFavouritePoemSearchResults = (filteredNotFavPoems) => ({
  type: SearchActionTypes.SET_NOT_FAVOURITE_POEMS_SEARCH_RESULTS,
  payload: filteredNotFavPoems,
});
