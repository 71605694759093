const FavouritesActionTypes = {
  ADD_POEM_TO_FAVOURITES_START: "ADD_POEM_TO_FAVOURITES_START",
  REMOVE_POEM_FROM_FAVOURITES_START: "REMOVE_POEM_FROM_FAVOURITES_START",
  ADD_POEM_TO_FAVOURITES_SUCCESS: "ADD_POEM_TO_FAVOURITES_SUCCESS",
  REMOVE_POEM_FROM_FAVOURITES_SUCCESS: "REMOVE_POEM_FROM_FAVOURITES_SUCCESS",
  ADD_POEM_TO_FAVOURITES_FAIL: "ADD_POEM_TO_FAVOURITES_FAIL",
  REMOVE_POEM_FROM_FAVOURITES_FAIL: "REMOVE_POEM_FROM_FAVOURITES_FAIL",

  REMOVE_ANY_FAVOURITE_NO_LONGER_IN_POEM_LIST:
    "REMOVE_ANY_FAVOURITE_NO_LONGER_IN_POEM_LIST",
};

export default FavouritesActionTypes;
