import styled from "styled-components";

export const ErrorImageOverlay = styled.div`
  margin-top: 100px;
  padding: 0 15px;
  min-height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.h2`
  font-size: 2em;
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  margin-bottom: 20px;
`;

export const SendEmailButton = styled.div`
  width: 300px;
  max-width: 400px;
  min-height: 35px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border-color: ${(props) => props.theme.primaryColor} !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.3rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :hover {
    background-color: ${(props) => props.theme.boxColor3};
    border-color: ${(props) => props.theme.boxColor3} !important;
    cursor: pointer;
    color: ${(props) => props.theme.primaryColor};
  }
`;
