import PoemsActionTypes from "./poems.types";

export const loadPoemsStart = () => ({
  type: PoemsActionTypes.LOAD_POEMS_START,
});

export const loadPoemsSuccess = () => ({
  type: PoemsActionTypes.LOAD_POEMS_SUCCESS,
});

export const loadPoemsFail = (errorMessage) => ({
  type: PoemsActionTypes.LOAD_POEMS_FAIL,
  payload: errorMessage,
});

export const fetchPoemsStart = () => ({
  type: PoemsActionTypes.FETCH_POEMS_START,
});

export const fetchPoemsSuccess = (fetchedPoems) => ({
  type: PoemsActionTypes.FETCH_POEMS_SUCCESS,
  payload: fetchedPoems,
});

export const fetchPoemsFail = (errorMessage) => ({
  type: PoemsActionTypes.FETCH_POEMS_FAIL,
  payload: errorMessage,
});
