import ThemeActionTypes from "./theme.types";
import { lightTheme } from "../../global-styles/themes/themes";

const INITIAL_STATE = {
  themes: [],
  themesLoading: false,
  themesFetched: false,

  currentTheme: lightTheme,
  themeLoading: false,
  themeSet: true,

  errorMessage: "",
};

const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemeActionTypes.LOAD_THEMES_START:
      return {
        ...state,
        themesLoading: true,
        themesFetched: false,
      };
    case ThemeActionTypes.LOAD_THEMES_SUCCESS:
      return {
        ...state,
        themes: action.payload ? action.payload : state.themes,
        themesLoading: false,
        themesFetched: true,
      };
    case ThemeActionTypes.LOAD_THEMES_FAIL:
      return {
        ...state,
        themesLoading: false,
        themesFetched: false,
        errorMessage: action.payload,
      };
    case ThemeActionTypes.SWITCH_THEMES_START:
      return {
        ...state,
        themeLoading: true,
        themeSet: false,
      };
    case ThemeActionTypes.SWITCH_THEMES_SUCCESS:
      return {
        ...state,
        currentTheme: action.payload,
        themeLoading: false,
        themeSet: true,
      };
    case ThemeActionTypes.SWITCH_THEMES_FAIL:
      return {
        ...state,
        themeLoading: false,
        themeSet: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default themeReducer;
