import FavouritesActionTypes from "./favourites.types";
import { removeNonExistingItem } from "./favourites.utils";

const INITIAL_STATE = {
  allFavourites: [],
  errorMessage: "",
};

const favouritesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_START:
    case FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_START:
      return {
        ...state,
      };

    case FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_SUCCESS:
    case FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_SUCCESS:
      return {
        ...state,
        allFavourites: action.payload,
      };
    case FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_FAIL:
    case FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FavouritesActionTypes.REMOVE_ANY_FAVOURITE_NO_LONGER_IN_POEM_LIST:
      return {
        ...state,
        allFavourites: removeNonExistingItem(
          action.payload,
          state.allFavourites
        ),
      };
    default:
      return state;
  }
};

export default favouritesReducer;
