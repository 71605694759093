import React from "react";
import { connect } from "react-redux";
import { createCrashReport } from "../../redux/app/app.actions";
import { selectInstanceID } from "../../redux/app/app.selectors";

import {
  ErrorImageOverlay,
  ErrorText,
  SendEmailButton,
} from "./ErrorBoundary.styles";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      instanceID: this.props.instanceID,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      this.props.createCrashReport();
      // You can render any custom fallback UI
      return (
        <ErrorImageOverlay>
          <ErrorText>
            Ajjaj, Valami félrement. Kérlek próbáld újra és ha továbbra sem
            működik, az alábbi gombra kattintva tudsz emailt küldeni és mi a
            lehető leggyorsabban megoldjuk a problémád és kapcsolatba lépünk
            veled.
          </ErrorText>
          <ErrorText>
            <a
              href={`mailto:penyo.bigyo@gmail.com?Subject=Hiba%20futás%20közben.%20App%20ID:%20${this.state.instanceID}`}
            >
              <SendEmailButton>Email küldése most</SendEmailButton>
            </a>
          </ErrorText>
          <ErrorText>
            Amennyiben ez a link nem működik, kérlek próbálj máshonnan emailt
            küldeni a penyo.bigyo@gmail.com email címre és add az alábbi App
            ID-t az emailedhez tárgyként.
            <br /> App ID: <br />"
            {this.state.instanceID
              ? this.state.instanceID
              : "Nem található App ID"}
            "
          </ErrorText>
          <ErrorText>
            Köszönjük segítséged és sajnáljuk hogy probléma történt :(
          </ErrorText>
        </ErrorImageOverlay>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  instanceID: selectInstanceID(state),
});

const mapDispatchToProps = (dispatch) => ({
  createCrashReport: () => dispatch(createCrashReport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
