// globalStyles.js
// font-family: 'Fuzzy Bubbles', cursive;
// font-family: 'Source Sans Pro', sans-serif;
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.primaryBackgroundColor};
    color:  ${(props) => props.theme.primaryColor};
  }
  *{
    font-family: 'Fuzzy Bubbles', cursive !important;

  }
  .container, .container-fluid{
      padding: 0 !important;
  }
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  a{
    text-decoration: none;
    color: ${(props) => props.theme.primaryColor} !important;
  }
  .bg-navBG{
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
  }
  .mh-5vh{
    max-height: 5vh;
  }
  .input-field{
    background-color: ${(props) => props.theme.secondaryBackgroundColor};
    color: ${(props) => props.theme.primaryColor};
    :focus {
      color: ${(props) => props.theme.primaryColor};
      background-color: ${(props) => props.theme.secondaryBackgroundColor};
      border-color: ${(props) => props.theme.borderColor};
      outline: 0;
      box-shadow: 0 0 0 0.25rem ${(props) => props.theme.boxColor2};
    }
  }
  .form-control::placeholder, .form-control::-ms-input-placeholder{
    color: ${(props) => props.theme.primaryColor};
  }

`;

export default GlobalStyle;
