import React, { lazy, useEffect, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { selectCurrentTheme } from "./redux/theme/theme.selectors";
import { HelmetProvider } from "react-helmet-async";

import GlobalStyle from "./global-styles/globalStyles";

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import SpinnerOverlay from "./components/SpinnerOverlay/SpinnerOverlay";
import {
  poemListContentChangeDetected,
  startApp,
} from "./redux/app/app.actions";
import Navigation from "./routes/Navigation/Navigation";

import { collection, onSnapshot, query } from "firebase/firestore";
import { fetchPoemsStart } from "../../frontend/src/redux/poems/poems.actions";
import { db } from "../../frontend/src/utils/firebase/firebase.utils";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const FourOFourPage = lazy(() => import("./pages/FourOFourPage/FourOFourPage"));
const PoemPage = lazy(() => import("./pages/PoemPage/PoemPage"));
const PoemsListPage = lazy(() => import("./pages/PoemsListPage/PoemsListPage"));

const App = ({ currentTheme, startApp, poemListContentChangeDetected }) => {
  useEffect(() => {
    const realtimePoemListChangesListener = async () => {
      const q = query(collection(db, "poems"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        poemListContentChangeDetected();
      });
    };
    realtimePoemListChangesListener();
  }, []);
  useEffect(() => {
    startApp();
  }, []);
  return (
    <ThemeProvider theme={currentTheme}>
      <HelmetProvider>
        <GlobalStyle />
        <ErrorBoundary>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense
                  fallback={
                    <SpinnerOverlay
                      spinnerText="Oldal betöltése folyamatban ..."
                      isSuspense
                    />
                  }
                >
                  <Navigation />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense
                    fallback={
                      <SpinnerOverlay
                        spinnerText="Oldal betöltése folyamatban ..."
                        isSuspense
                      />
                    }
                  >
                    <HomePage />
                  </Suspense>
                }
              />
              <Route
                path="/mesek/"
                element={
                  <Suspense
                    fallback={
                      <SpinnerOverlay
                        spinnerText="Oldal betöltése folyamatban ..."
                        isSuspense
                      />
                    }
                  >
                    <PoemsListPage />
                  </Suspense>
                }
              />
              <Route
                path="/mesek/:poemSlug"
                element={
                  <Suspense
                    fallback={
                      <SpinnerOverlay
                        spinnerText="Oldal betöltése folyamatban ..."
                        isSuspense
                      />
                    }
                  >
                    <PoemPage />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense
                    fallback={
                      <SpinnerOverlay
                        spinnerText="Oldal betöltése folyamatban ..."
                        isSuspense
                      />
                    }
                  >
                    <FourOFourPage />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </ErrorBoundary>
      </HelmetProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  currentTheme: selectCurrentTheme(state),
});

const mapDispatchToProps = (dispatch) => ({
  startApp: () => dispatch(startApp()),
  poemListContentChangeDetected: () =>
    dispatch(poemListContentChangeDetected()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
