import { createSelector } from "reselect";

const selectTheme = (state) => state.theme;

/* Themes */
export const selectAvailableThemes = createSelector(
  [selectTheme],
  (theme) => theme.themes
);

export const selectThemesLoading = createSelector(
  [selectTheme],
  (theme) => theme.themesLoading
);
export const selectThemesFetched = createSelector(
  [selectTheme],
  (theme) => theme.themesFetched
);

/* Theme */
export const selectCurrentTheme = createSelector(
  [selectTheme],
  (theme) => theme.currentTheme
);

export const selectThemeLoading = createSelector(
  [selectTheme],
  (theme) => theme.themeLoading
);

export const selectThemeSet = createSelector(
  [selectTheme],
  (theme) => theme.themesSet
);
