export const lightTheme = {
  name: "lightTheme",
  primaryBackgroundColor: "#f1eff2",
  secondaryBackgroundColor: "#8bb5b0",
  primaryColor: "#53250d",
  boxColor1: "#a091b0",
  boxColor2: "#edb88b",
  boxColor3: "#8e561d",
  borderColor: "#391705",
};

export const darkTheme = {
  name: "darkTheme",
  primaryBackgroundColor: "#391705",
  secondaryBackgroundColor: "#8e561d",
  primaryColor: "#edb88b",
  boxColor1: "#a091b0",
  boxColor2: "#edb88b",
  boxColor3: "#8e561d",
  borderColor: "#53250d",
};
