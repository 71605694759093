export const searchForMatchInTitleAndVerseList = (
  poemList,
  searchText,
  searchInVerse
) => {
  const result = poemList.filter((poem) => {
    if (searchText === "") {
      return poemList;
    } else {
      let match = poem.title.toLowerCase().includes(searchText);
      if (!match && searchInVerse) {
        poem.verseList.filter((v) => {
          let vmatch = v.verse.toLowerCase().includes(searchText);
          if (!vmatch) {
            return false;
          }
          match = true;
          return true;
        });
      }
      return match;
    }
  });
  return result;
};
