import React from "react";
import { Spinner } from "react-bootstrap";
import {
  SpinnerContainer,
  SpinnerText,
  StyledSpinnerContainer,
} from "./SpinnerOverlay.styles";
const SpinnerOverlay = ({ spinnerText, isSuspense }) => {
  return (
    <SpinnerContainer fullscreen={isSuspense ? "true" : "false"}>
      <SpinnerText>{spinnerText ? spinnerText : ""} </SpinnerText>
      <StyledSpinnerContainer>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </StyledSpinnerContainer>
    </SpinnerContainer>
  );
};

export default SpinnerOverlay;
