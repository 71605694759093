import ThemeActionTypes from "./theme.types";

export const loadThemesStart = () => ({
  type: ThemeActionTypes.LOAD_THEMES_START,
});

export const loadThemesSuccess = (themes) => ({
  type: ThemeActionTypes.LOAD_THEMES_SUCCESS,
  payload: themes,
});

export const loadThemesFail = (errorMessage) => ({
  type: ThemeActionTypes.LOAD_THEMES_FAIL,
  payload: errorMessage,
});

export const switchThemesStart = () => ({
  type: ThemeActionTypes.SWITCH_THEMES_START,
});

export const switchThemesSuccess = (theme) => ({
  type: ThemeActionTypes.SWITCH_THEMES_SUCCESS,
  payload: theme,
});

export const switchThemesFail = (errorMessage) => ({
  type: ThemeActionTypes.SWITCH_THEMES_FAIL,
  payload: errorMessage,
});
