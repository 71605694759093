import AppActionTypes from "./app.types";

export const startApp = () => ({
  type: AppActionTypes.START_APP,
});

export const checkInstanceIDStart = () => ({
  type: AppActionTypes.CHECK_INSTANCE_ID_START,
});

export const instanceIDFound = (instanceID) => ({
  type: AppActionTypes.INSTANCE_ID_FOUND,
  payload: instanceID,
});

export const instanceIDNotFound = () => ({
  type: AppActionTypes.INSTANCE_ID_NOT_FOUND,
});

export const checkInstanceIDFail = (errorMessage) => ({
  type: AppActionTypes.CHECK_INSTANCE_ID_FAIL,
  payload: errorMessage,
});

export const createInstanceIDStart = () => ({
  type: AppActionTypes.CREATE_INSTANCE_ID_START,
});

export const createInstanceIDSuccess = (instanceID) => ({
  type: AppActionTypes.CREATE_INSTANCE_ID_SUCCESS,
  payload: instanceID,
});

export const createInstanceIDFail = (errorMessage) => ({
  type: AppActionTypes.CREATE_INSTANCE_ID_FAIL,
  payload: errorMessage,
});

export const loadAssetsStart = () => ({
  type: AppActionTypes.LOAD_ASSETS_START,
});

export const loadAssetsSuccess = () => ({
  type: AppActionTypes.LOAD_ASSETS_SUCCESS,
});
export const loadAssetsFail = (errorMessage) => ({
  type: AppActionTypes.LOAD_ASSETS_FAIL,
  payload: errorMessage,
});

export const createCrashReport = () => ({
  type: AppActionTypes.CREATE_CRASH_REPORT,
});

export const poemListContentChangeDetected = () => ({
  type: AppActionTypes.POEM_LIST_CONTENT_CHANGE_DETECTED,
});

export const registerError = (errorMessage) => ({
  type: AppActionTypes.REGISTER_ERROR,
  payload: errorMessage,
});

export const registerFavouriteAddition = (poem) => ({
  type: AppActionTypes.REGISTER_FAVOURITE_ADDITION,
  payload: poem,
});

export const registerFavouriteRemoval = (poem) => ({
  type: AppActionTypes.REGISTER_FAVOURITE_REMOVAL,
  payload: poem,
});

export const registerPoemOpened = (poem) => ({
  type: AppActionTypes.REGISTER_POEM_OPENED,
  payload: poem,
});
