import { all, call, put, select, takeEvery } from "redux-saga/effects";
import ThemeActionTypes from "./theme.types";
import { darkTheme, lightTheme } from "../../global-styles/themes/themes";
import {
  loadThemesFail,
  loadThemesSuccess,
  switchThemesFail,
  switchThemesSuccess,
} from "./theme.actions";

export const selectCurrentTheme = (state) => state.theme.currentTheme;
export const selectAvailableThemes = (state) => state.theme.themes;

function* loadThemes(action) {
  try {
    const availableThemes = select(selectAvailableThemes);
    if (!availableThemes.length) {
      const themes = [lightTheme, darkTheme];
      yield put(loadThemesSuccess(themes));
    } else yield put(loadThemesSuccess());
  } catch (e) {
    yield put(loadThemesFail(e.message));
  }
}

function* switchThemes(action, ownProps) {
  try {
    const currentTheme = yield select(selectCurrentTheme);
    const availableThemes = yield select(selectAvailableThemes);
    if (availableThemes.length > 1) {
      yield put(
        switchThemesSuccess(
          availableThemes.find((theme) => theme.name !== currentTheme.name)
        )
      );
    } else
      yield put(
        switchThemesFail(
          "Csak egy téma érhető el ebben a pillanatban. Kérlek frissítsd az oldalt és próbáld újra."
        )
      );
  } catch (e) {
    yield put(switchThemesFail(e.message));
  }
}

export function* onThemesSwith() {
  yield takeEvery([ThemeActionTypes.SWITCH_THEMES_START], switchThemes);
}

export function* onThemesLoad() {
  yield takeEvery([ThemeActionTypes.LOAD_THEMES_START], loadThemes);
}

export function* themeSagas() {
  yield all([call(onThemesLoad), call(onThemesSwith)]);
}
