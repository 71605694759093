const AppActionTypes = {
  START_APP: "START_APP",
  CREATE_CRASH_REPORT: "CREATE_CRASH_REPORT",

  CHECK_INSTANCE_ID_START: "CHECK_INSTANCE_ID_START",
  INSTANCE_ID_FOUND: "INSTANCE_ID_FOUND",
  INSTANCE_ID_NOT_FOUND: "INSTANCE_ID_NOT_FOUND",
  CHECK_INSTANCE_ID_FAIL: "CHECK_INSTANCE_ID_FAIL",

  CREATE_INSTANCE_ID_START: "CREATE_INSTANCE_ID_START",
  CREATE_INSTANCE_ID_SUCCESS: "CREATE_INSTANCE_ID_SUCCESS",
  CREATE_INSTANCE_ID_FAIL: "CREATE_INSTANCE_ID_FAIL",

  LOAD_ASSETS_START: "LOAD_ASSETS_START",
  LOAD_ASSETS_SUCCESS: "LOAD_ASSETS_SUCCESS",
  LOAD_ASSETS_FAIL: "LOAD_ASSETS_FAIL",

  POEM_LIST_CONTENT_CHANGE_DETECTED: "POEM_LIST_CONTENT_CHANGE_DETECTED",

  REGISTER_ERROR: "REGISTER_ERROR",

  REGISTER_FAVOURITE_ADDITION: "REGISTER_FAVOURITE_ADDITION",
  REGISTER_FAVOURITE_REMOVAL: "REGISTER_FAVOURITE_REMOVAL",

  REGISTER_POEM_OPENED: "REGISTER_POEM_OPENED",
};

export default AppActionTypes;
