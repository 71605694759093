const SearchActionTypes = {
  SET_SEARCH_PHRASE: "SET_SEARCH_PHRASE",
  SET_SEARCH_IN_VERSE: "SET_SEARCH_IN_VERSE",
  SEARCH_POEMS_START: "SEARCH_POEMS_START",
  SEARCH_POEMS_SUCCESS: "SEARCH_POEMS_SUCCESS",
  SEARCH_POEMS_FAIL: "SEARCH_POEMS_FAIL",
  SET_FAVOURITE_POEMS_SEARCH_RESULTS: "SET_FAVOURITE_POEMS_SEARCH_RESULTS",
  SET_NOT_FAVOURITE_POEMS_SEARCH_RESULTS:
    "SET_NOT_FAVOURITE_POEMS_SEARCH_RESULTS",
};

export default SearchActionTypes;
