const PoemsActionTypes = {
  LOAD_POEMS_START: "LOAD_POEMS_START",
  LOAD_POEMS_SUCCESS: "LOAD_POEMS_SUCCESS",
  LOAD_POEMS_FAIL: "LOAD_POEMS_FAIL",
  FETCH_POEMS_START: "FETCH_POEMS_START",
  FETCH_POEMS_SUCCESS: "FETCH_POEMS_SUCCESS",
  FETCH_POEMS_FAIL: "FETCH_POEMS_FAIL",
  LOAD_POEM_START: "LOAD_POEM_START",
  LOAD_POEM_SUCCESS: "LOAD_POEM_SUCCESS",
  LOAD_POEM_FAIL: "LOAD_POEM_FAIL",
};

export default PoemsActionTypes;
