import { all, call } from "redux-saga/effects";

import { poemsSagas } from "./poems/poems.sagas";
import { searchSagas } from "./search/search.sagas";
import { themeSagas } from "./theme/theme.sagas";
import { favouritesSagas } from "./favourites/favourites.sagas";
import { appSagas } from "./app/app.sagas";

export default function* rootSaga() {
  yield all([
    call(appSagas),
    call(searchSagas),
    call(themeSagas),
    call(poemsSagas),
    call(favouritesSagas),
  ]);
}
