import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

const Footer = () => {
  return (
    <Navbar bg="navBG">
      <Container className="justify-content-center">
        <div className="text-center">
          <h4 style={{ fontSize: "1.2em" }}>
            © 2022 Zelena Réka & Pigniczki Zsóka <br /> Minden jog fenntartva.
          </h4>
        </div>
      </Container>
    </Navbar>
  );
};

export default Footer;
