const ThemeActionTypes = {
  LOAD_THEMES_START: "LOAD_THEMES_START",
  LOAD_THEMES_SUCCESS: "LOAD_THEMES_SUCCESS",
  LOAD_THEMES_FAIL: "LOAD_THEMES_FAIL",
  SWITCH_THEMES_START: "SWITCH_THEMES_START",
  SWITCH_THEMES_SUCCESS: "SWITCH_THEMES_SUCCESS",
  SWITCH_THEMES_FAIL: "SWITCH_THEMES_FAIL",
};

export default ThemeActionTypes;
