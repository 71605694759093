import FavouritesActionTypes from "./favourites.types";

export const addPoemToFavouritesStart = (poem) => ({
  type: FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_START,
  payload: poem,
});

export const removePoemFromFavouritesStart = (poem) => ({
  type: FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_START,
  payload: poem,
});

export const addPoemToFavouritesSuccess = (updatedFavourites) => ({
  type: FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_SUCCESS,
  payload: updatedFavourites,
});

export const removePoemFromFavouritesSuccess = (updatedFavourites) => ({
  type: FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_SUCCESS,
  payload: updatedFavourites,
});

export const addPoemToFavouritesFail = (errorMessage) => ({
  type: FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_FAIL,
  payload: errorMessage,
});

export const removePoemFromFavouritesFail = (errorMessage) => ({
  type: FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_FAIL,
  payload: errorMessage,
});

export const removeAnyFavouriteNoLongerInPoemList = (allPoems) => ({
  type: FavouritesActionTypes.REMOVE_ANY_FAVOURITE_NO_LONGER_IN_POEM_LIST,
  payload: allPoems,
});
