export const addOrRemoveFavourite = (currentFavourites, poem) => {
  if (currentFavourites) {
    const alreadyFavourite = currentFavourites.find((p) => p.id === poem.id);
    if (alreadyFavourite) {
      return currentFavourites.filter((p) => p.id !== poem.id);
    } else {
      return [...currentFavourites, poem];
    }
  }
};

export const removeNonExistingItem = (allPoems, currentFavourites) => {
  console.log("allPoems:", allPoems);
  console.log("favs:", currentFavourites);
  let newFavourites = [];
  currentFavourites.forEach((poem) => {
    if (allPoems.find((p) => p.id === poem.id) !== undefined) {
      newFavourites.push(poem);
    }
  });
  console.log(newFavourites);
  return newFavourites;
};
