// https://react-bootstrap.netlify.app/layout/breakpoints/

export const layoutBreakpoints = {
  xs: "310px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};
