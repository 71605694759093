import AppActionTypes from "./app.types";

const INITIAL_STATE = {
  instanceID: "",
  instanceCreatedOn: "",
  anonymUser: true,

  lastDataFetchedOn: "",

  favouritesAdded: [],
  favouritesRemoved: [],

  instanceErrors: [],
  crashReports: [],
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionTypes.CHECK_INSTANCE_ID_START:
    case AppActionTypes.INSTANCE_ID_FOUND:
    case AppActionTypes.INSTANCE_ID_NOT_FOUND:
    case AppActionTypes.CREATE_INSTANCE_ID_START:
    case AppActionTypes.LOAD_ASSETS_START:
    case AppActionTypes.LOAD_ASSETS_SUCCESS:
      return {
        ...state,
      };
    case AppActionTypes.CREATE_CRASH_REPORT:
      return {
        ...state,
        crashReports: { crashedOn: new Date().toISOString() },
      };
    case AppActionTypes.CREATE_INSTANCE_ID_SUCCESS:
      return {
        ...state,
        instanceID: action.payload.instanceID,
        instanceCreatedOn: action.payload.instanceCreatedOn,
      };
    case AppActionTypes.CHECK_INSTANCE_ID_FAIL:
    case AppActionTypes.CREATE_INSTANCE_ID_FAIL:
    case AppActionTypes.LOAD_ASSETS_FAIL:
    case AppActionTypes.REGISTER_ERROR:
      return {
        ...state,
        instanceErrors: [
          ...state.instanceErrors,
          {
            errorOn: new Date().toISOString(),
            errorMessage: action.payload,
          },
        ],
      };
    case AppActionTypes.REGISTER_FAVOURITE_ADDITION:
      return {
        ...state,
        favouritesAdded: [
          ...state.favouritesAdded,
          {
            addedOn: new Date().toISOString(),
            poem: action.payload.id,
            slug: action.payload.slug,
          },
        ],
      };
    case AppActionTypes.REGISTER_FAVOURITE_REMOVAL:
      return {
        ...state,
        favouritesRemoved: [
          ...state.favouritesRemoved,
          {
            removedOn: new Date().toISOString(),
            poem: action.payload.id,
            slug: action.payload.slug,
          },
        ],
      };
    default:
      return state;
  }
};

export default appReducer;
