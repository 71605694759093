import {
  all,
  call,
  put,
  select,
  takeEvery,
  race,
  delay,
  take,
  takeLatest,
} from "redux-saga/effects";
import AppActionTypes from "./app.types";
import {
  instanceIDFound,
  createInstanceIDStart,
  createInstanceIDSuccess,
  createInstanceIDFail,
  instanceIDNotFound,
  checkInstanceIDFail,
  loadAssetsSuccess,
  loadAssetsFail,
  loadAssetsStart,
  registerFavouriteAddition,
  registerError,
  registerFavouriteRemoval,
} from "./app.actions";
import { createAppInstanceInFirestore } from "../../utils/firebase/firebase.utils";
import {
  fetchPoemsStart,
  loadPoemsStart,
  loadPoemsSuccess,
} from "../poems/poems.actions";
import SearchActionTypes from "../search/search.types";
import { loadThemesStart } from "../theme/theme.actions";
import ThemeActionTypes from "../theme/theme.types";
import FavouritesActionTypes from "../favourites/favourites.types";
import {
  addPoemToFavouritesFail,
  addPoemToFavouritesSuccess,
  removeAnyFavouriteNoLongerInPoemList,
  removePoemFromFavouritesFail,
  removePoemFromFavouritesSuccess,
} from "../favourites/favourites.actions";
import PoemsActionTypes from "../poems/poems.types";

export const selectInstanceID = (state) => state.app.instanceID;

export const selectAvailableThemes = (state) => state.theme.themes;

export const selectCurrentFavourites = (state) =>
  state.favourites.allFavourites;

export const selectAllPoems = (state) => state.poems.allPoems;

/* INSTANCE SETUP */

function* checkExistingInstanceID(action) {
  try {
    const instanceID = yield select(selectInstanceID);
    if (instanceID) yield put(instanceIDFound());
    else yield put(instanceIDNotFound());
  } catch (e) {
    yield put(checkInstanceIDFail(e.message));
  }
}

function* createInstanceID(action) {
  try {
    yield put(createInstanceIDStart());
    const { instanceInfo, timeout } = yield race({
      instanceInfo: createAppInstanceInFirestore(),
      timeout: delay(1000),
    });

    if (instanceInfo) {
      yield put(createInstanceIDSuccess(instanceInfo));
    } else {
      yield put(createInstanceIDFail("timeout: ", timeout));
    }
  } catch (e) {
    yield put(createInstanceIDFail(e.message));
  }
}

function* loadAssets(action) {
  try {
    yield put(loadAssetsStart());
    yield put(loadPoemsStart());
    yield take(SearchActionTypes.SEARCH_POEMS_SUCCESS);

    const availableThemes = select(selectAvailableThemes);
    if (!availableThemes.length) {
      yield put(loadThemesStart());
      yield take(ThemeActionTypes.LOAD_THEMES_SUCCESS);
    }
    yield put(loadAssetsSuccess());
  } catch (e) {
    yield put(loadAssetsFail(e.message));
  }
}

export function* onCheckingInsatnceID() {
  yield takeEvery(
    [AppActionTypes.CHECK_INSTANCE_ID_START],
    checkExistingInstanceID
  );
}

export function* onInsatnceIDNotFound() {
  yield takeEvery([AppActionTypes.INSTANCE_ID_NOT_FOUND], createInstanceID);
}

export function* onLoadingAssets() {
  yield takeEvery(
    [
      AppActionTypes.INSTANCE_ID_FOUND,
      AppActionTypes.CREATE_INSTANCE_ID_SUCCESS,
    ],
    loadAssets
  );
}

export function* onStartingApp() {
  yield takeLatest([AppActionTypes.START_APP], checkExistingInstanceID);
}
/* POEMLIST CONTENT CHANGE IN FIREBASE  */

export function* refetchPoemList(action) {
  try {
    yield put(fetchPoemsStart());
    const { success, failure } = yield race({
      success: take(PoemsActionTypes.FETCH_POEMS_SUCCESS),
      failure: take(PoemsActionTypes.FETCH_POEMS_FAIL),
    });
    const favourites = select(selectCurrentFavourites);
    if (success && favourites) {
      const allPoems = yield select(selectAllPoems);
      yield put(removeAnyFavouriteNoLongerInPoemList(allPoems));
      yield put(loadPoemsSuccess());
    }
  } catch (e) {
    yield put(registerError(e.message));
  }
}

export function* onPoemListContentChange() {
  yield takeEvery(
    [AppActionTypes.POEM_LIST_CONTENT_CHANGE_DETECTED],
    refetchPoemList
  );
}

/* USER ACTIONS */

function* regFavouriteAddition(action) {
  try {
    const { success, failure } = yield race({
      success: yield take(addPoemToFavouritesSuccess),
      failure: yield take(addPoemToFavouritesFail),
    });
    if (success) {
      yield put(registerFavouriteAddition(action.payload));
    } else {
      yield put(registerError("favourite addition failed"));
    }
  } catch (e) {
    yield put(registerError(e.message));
  }
}

function* regFavouriteRemoval(action) {
  try {
    const { success, failure } = yield race({
      success: yield take(removePoemFromFavouritesSuccess),
      failure: yield take(removePoemFromFavouritesFail),
    });
    if (success) {
      yield put(registerFavouriteRemoval(action.payload));
    } else {
      yield put(registerError("favourite removal failed"));
    }
  } catch (e) {
    yield put(registerError(e.message));
  }
}

/* WATCH */

function* onRegisteringFavouriteAddition() {
  yield takeEvery(
    [FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_START],
    regFavouriteAddition
  );
}

function* onRegisteringFavouriteRemoval() {
  yield takeEvery(
    [FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_START],
    regFavouriteRemoval
  );
}

/* CALL */
export function* appSagas() {
  yield all([
    call(onStartingApp),
    call(onCheckingInsatnceID),
    call(onInsatnceIDNotFound),
    call(onLoadingAssets),

    call(onPoemListContentChange),

    call(onRegisteringFavouriteAddition),
    call(onRegisteringFavouriteRemoval),
  ]);
}
