// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFirestore,
  collection,
  query,
  getDocs,
  addDoc,
  where,
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// https://firebase.google.com/docs/projects/api-keys
// Import the functions you need from the SDKs you need

const firebaseConfig = {
  apiKey: "AIzaSyAUbYQYxdS4_8yU35lT-kDDNZNGFAGGcNY",
  authDomain: "versesmesek-706db.firebaseapp.com",
  projectId: "versesmesek-706db",
  storageBucket: "versesmesek-706db.appspot.com",
  messagingSenderId: "1090190462438",
  appId: "1:1090190462438:web:cec996a4eb357de2529a27",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAf3KPVryf-ozFFTv0ONOkpqj8VUP0A52E",
//   authDomain: "vs-mesek-dev.firebaseapp.com",
//   projectId: "vs-mesek-dev",
//   storageBucket: "vs-mesek-dev.appspot.com",
//   messagingSenderId: "561872966371",
//   appId: "1:561872966371:web:39868f491e40576afa6bd6",
//   measurementId: "G-Z064PF3FXW",
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore();

export const fetchPoemsFromFirestore = async () => {
  const q = query(collection(db, "poems"), where("isPublished", "==", true));
  const querySnapshot = await getDocs(q);
  const allPoems = [];
  querySnapshot.forEach((doc) => {
    allPoems.push(doc.data());
  });
  return allPoems;
};

export const createAppInstanceInFirestore = async () => {
  const dateNow = new Date().toISOString();
  const appData = {
    instanceCreatedOn: dateNow,
    anonymUser: true,

    lastDataFetchedOn: dateNow,

    favouritesAdded: [],
    favouritesRemoved: [],

    instanceErrors: [],
    crashReports: [],
  };

  const docRef = await addDoc(collection(db, "appInstances"), appData);
  const instanceInfo = { instanceID: docRef.id, instanceCreatedOn: dateNow };
  return instanceInfo;
};
