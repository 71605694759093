import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import themeReducer from "./theme/theme.reducer";
import favouritesReducer from "./favourites/favourites.reducer";
import poemsReducer from "./poems/poems.reducer";
import searchReducer from "./search/search.reducer";
import appReducer from "./app/app.reducer";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme", "favourites", "search", "poems", "app"],
};

const rootReducer = combineReducers({
  app: appReducer,
  theme: themeReducer,
  favourites: favouritesReducer,
  poems: poemsReducer,
  search: searchReducer,
});

export default persistReducer(persistConfig, rootReducer);
