import SearchActionTypes from "./search.types";
import { searchForMatchInTitleAndVerseList } from "./search.utils";

const INITIAL_STATE = {
  searchText: "",
  searchInVerse: true,
  filteredFavourites: [],
  filteredNotFavourites: [],
  errorMessage: "",
  searchResultsSet: false,
};

const SearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchActionTypes.SET_SEARCH_PHRASE:
      return {
        ...state,
        searchText: action.payload,
        searchResultsSet: false,
      };
    case SearchActionTypes.SET_SEARCH_IN_VERSE:
      return {
        ...state,
        searchInVerse: !state.searchInVerse,
        searchResultsSet: false,
      };
    case SearchActionTypes.SET_FAVOURITE_POEMS_SEARCH_RESULTS:
      return {
        ...state,
        filteredFavourites: searchForMatchInTitleAndVerseList(
          action.payload,
          state.searchText,
          state.searchInVerse
        ),
      };
    case SearchActionTypes.SET_NOT_FAVOURITE_POEMS_SEARCH_RESULTS:
      return {
        ...state,
        filteredNotFavourites: searchForMatchInTitleAndVerseList(
          action.payload,
          state.searchText,
          state.searchInVerse
        ),
      };
    case SearchActionTypes.SEARCH_POEMS_START:
      return {
        ...state,
        searchResultsSet: false,
      };
    case SearchActionTypes.SEARCH_POEMS_SUCCESS:
      return {
        ...state,
        searchResultsSet: true,
      };
    case SearchActionTypes.SEARCH_POEMS_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
        searchResultsSet: false,
      };
    default:
      return state;
  }
};

export default SearchReducer;
