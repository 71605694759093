import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addPoemToFavouritesFail,
  addPoemToFavouritesSuccess,
  removePoemFromFavouritesFail,
  removePoemFromFavouritesSuccess,
} from "./favourites.actions";

import FavouritesActionTypes from "./favourites.types";
import { addOrRemoveFavourite } from "./favourites.utils";

const selectAllFavourites = (state) => state.favourites.allFavourites;

function* addPoemToFavourites(action) {
  try {
    const allFavourites = yield select(selectAllFavourites);
    const updatedFavourites = yield addOrRemoveFavourite(
      allFavourites,
      action.payload
    );

    if (updatedFavourites)
      yield put(addPoemToFavouritesSuccess(updatedFavourites));
    else yield put(addPoemToFavouritesFail("Unable to add to favourites"));
  } catch (e) {
    yield put(addPoemToFavouritesFail(e.message));
  }
}

function* removePoemFromFavourites(action) {
  try {
    const allFavourites = yield select(selectAllFavourites);
    const updatedFavourites = yield addOrRemoveFavourite(
      allFavourites,
      action.payload
    );
    if (updatedFavourites)
      yield put(removePoemFromFavouritesSuccess(updatedFavourites));
    else
      yield put(
        removePoemFromFavouritesFail("Unable to remove from favourites")
      );
  } catch (e) {
    yield put(removePoemFromFavouritesFail(e.message));
  }
}

export function* onAddingPoemToFavourites() {
  yield takeEvery(
    [FavouritesActionTypes.ADD_POEM_TO_FAVOURITES_START],
    addPoemToFavourites
  );
}
export function* onRemovingPoemFromFavourites() {
  yield takeEvery(
    [FavouritesActionTypes.REMOVE_POEM_FROM_FAVOURITES_START],
    removePoemFromFavourites
  );
}

export function* favouritesSagas() {
  yield all([
    call(onAddingPoemToFavourites),
    call(onRemovingPoemFromFavourites),
  ]);
}
