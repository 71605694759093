import { createSelector } from "reselect";

const selectApp = (state) => state.app;

export const selectInstanceID = createSelector(
  [selectApp],
  (app) => app.instanceID
);

export const selectIsAppCrashed = createSelector(
  [selectApp],
  (app) => app.appCrashed
);
